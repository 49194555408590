import request from '@/utils/request'
import {getToken} from '@/utils/auth';

// 查询接口列表
export function getUser(params) {
  if(params == undefined || params == null){
    params={}
  }
  // var params={};
  var token=getToken();
  if(!params.code && token){
      params.code=token;
  }
    return request({
      url: '/baseinfo/get_patient',
      method: 'get',
      params:params
    })
  }


  export function getMettingVideos(params) {
      return request({
        url: '/baseinfo/getMettingVideos',
        method: 'get',
        params:params
      })
    }

    export function getMettingVideo(params) {
      return request({
        url: '/baseinfo/getMettingVideo',
        method: 'get',
        params:params
      })
    }
    