import {getToken} from '@/utils/auth';

import request from '@/utils/request';




export function saveBaseInfo(params){
   var token=getToken();
   if(token){
        params.code=token;
   }
    return request({
        url: '/weixin_patients/bind_user.json',
        method: 'post',
        params:params
      })

}

export function getBaseInfo(params){
    var token=getToken();
    if(token){
        params.code=token;
    }
    return request({
        url: '/wxmain/getUserInfo',
        method: 'post',
        params:params
      })

}